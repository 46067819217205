import React from 'react'
import BuyMeACoffeeIcon from 'simple-icons/icons/buymeacoffee'
import GitHubIcon from 'simple-icons/icons/github'
import KeybaseIcon from 'simple-icons/icons/keybase'
import LinkedinIcon from 'simple-icons/icons/linkedin'
import MastodonIcon from 'simple-icons/icons/mastodon'
import PatreonIcon from 'simple-icons/icons/patreon'
import TwitterIcon from 'simple-icons/icons/twitter'
import { Link } from 'theme-ui'
import SimpleIcon from './SimpleIcon'

const icon = {
  buymeacoffee: BuyMeACoffeeIcon,
  github: GitHubIcon,
  keybase: KeybaseIcon,
  linkedin: LinkedinIcon,
  mastodon: MastodonIcon,
  patreon: PatreonIcon,
  twitter: TwitterIcon,
}

const baseUrl = {
  buymeacoffee: 'https://www.buymeacoffee.com/',
  github: 'https://github.com/',
  keybase: 'https://keybase.io/',
  linkedin: 'https://www.linkedin.com/in/',
  mastodon: 'https://',
  patreon: 'https://www.patreon.com/',
  twitter: 'https://twitter.com/',
}

function SocialLink({ name, username }) {
  const Icon = icon[name]

  return (
    <Link
      rel="me"
      href={`${baseUrl[name]}${username}`}
      sx={{
        display: 'inline-block',
        color: 'currentColor',
        p: 2,
        opacity: 0.9,
        transition: 'color 0.15s, transform 0.1s',
        ':hover': {
          opacity: 1,
          color: `#${Icon.hex}`,
          transform: 'scale(1.5)',
        },
      }}
      target="_blank"
    >
      <SimpleIcon icon={Icon} />
    </Link>
  )
}

export default SocialLink
