/* @jsx jsx */
import { jsx, Box } from 'theme-ui'

function SimpleIcon({ icon, size = '1em', fill = `currentColor`, style }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      sx={{
        fill,
        width: size,
        height: size,
        ...style,
      }}
    >
      <path d={icon.path} />
    </Box>
  )
}

export default SimpleIcon
