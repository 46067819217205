/* @jsx jsx */
import { jsx, Link } from 'theme-ui'

function InertLink({ children, as = 'a', ...props }) {
  return (
    <Link
      as={as}
      tabIndex="-1"
      css={{
        color: 'inherit',
        pointerEvents: 'none',
        textDecoration: 'none',
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

export default InertLink
