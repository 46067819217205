import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { Box, Flex, Heading, Image, NavLink } from 'theme-ui'
import InertLink from '../components/Link/InertLink'
import SEO from '../components/SEO'
import SocialLink from '../components/SocialLink'

function IndexPage() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            siteUrl
            social {
              keybase
              github
              twitter
              linkedin
              mastodon
              buymeacoffee
              patreon
            }
          }
        }
      }
    `
  )

  return (
    <>
      <SEO url={site.siteMetadata.siteUrl} opengraph={{ type: 'website' }} />

      <Flex
        className="h-card"
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          textAlign: 'center',
          p: 4,
        }}
      >
        <Heading sx={{ fontSize: 4 }}>Hey...</Heading>

        <Box sx={{ height: 128, width: 128, mt: 3, mb: 4 }}>
          <InertLink className="u-url" href="/" rel="me">
            <Image
              className="u-photo"
              alt={`${site.siteMetadata.author}'s Avatar`}
              src="/avatar.png"
            />
          </InertLink>
        </Box>

        <Heading as="h1" sx={{ fontSize: 6 }}>
          I'm <span className="p-name">{site.siteMetadata.author}</span>!
        </Heading>

        <Flex mt={4} sx={{ fontSize: 4 }}>
          <NavLink as={Link} to="/blog">
            Blog
          </NavLink>
        </Flex>

        <Box mt={4} sx={{ fontSize: 4 }}>
          {Object.entries(site.siteMetadata.social).map(([name, username]) => (
            <SocialLink key={name} name={name} username={username} />
          ))}
        </Box>

        <Flex mt={1} sx={{ fontSize: 3 }}>
          <NavLink href="https://go.muniftanjim.dev/subscribe" target="_blank">
            Subscribe
          </NavLink>
        </Flex>
      </Flex>
    </>
  )
}

export default IndexPage
